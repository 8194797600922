<template>
    <div class="privacy-policy small-intro" v-if="this.pageData">
        <div class="intro-section container">
            <div v-if="this.pageData.attributes.title">
                <h1 class="light"> {{ this.pageData.attributes.title }}</h1>
                <p v-html="this.pageData.attributes.body.value">
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "external-scholarships",
    components: {
    },
    data: () => {
        return {
            currentPageId: "eec0dbc4-e0d0-4d86-97e3-0c660b62a2e6",
            pageData: null,
        }
    },
    methods: {
    },
    mounted() {
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/page/${this.currentPageId}`, {
            method: "GET",
            mode: "cors",
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                this.pageData = json.data;

            })
            .catch(function (error) {
                console.log("Request failed", error);
            });
    }
}
</script>
<style lang="scss" scoped></style>